import Footer from './Home/Footer/Footer';
import Navbar from './Home/Navbar/Navbar'
import Page1 from './About/Page1/Page1';
import Page2 from './About/Page2/Page2';
import PdfButton from './About/PdfButton/PdfButton';
import Page3 from './About/Page3/Page3';
import Socials from './About/Socials/Socials';
function About() {

  return (
    <>
      <div className="About">
      <Navbar />
      <Page1 />
      <Page2 />
      <PdfButton/>
      <Page3/>
      <Socials/>
      <Footer />
      </div>
      <div style={{display:"none"}}>
        <h1>
            Ek Idea, India's digital content production agency and multimedia production company. Ek Idea is a dynamic creative agency offering cutting-edge studio video production, snackable content and everything in between.
        </h1>
      </div>
    </>
  );
}

export default About;
