import React, { useEffect } from 'react'
import './Footer.css'
import Aos from 'aos';
import 'aos/dist/aos.css';
const Footer = () => {
  useEffect(()=>{
    Aos.init({
      duration:900,
    })
  },[])
    const year = new Date().getFullYear();
  return (
    <footer className='footer'>
        Copyright &#169; {year}, Ek Idea. All rights reserved.
    </footer>
  )
}

export default Footer