import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// document.addEventListener("scroll",()=>{
//   var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
//   var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
//   var scrolled = (winScroll / height) * 100;
//   document.getElementById("myBar").style.height = scrolled + "%";
// })



document.addEventListener('scroll', () => {
  const cards = document.querySelectorAll('.card');
  const windowHeight = window.innerHeight;
  cards.forEach(card => {
    const rect = card.getBoundingClientRect();
    const cardMiddle = (rect.top + rect.bottom) / 2;
    if (cardMiddle >= windowHeight * 0.15 && cardMiddle <= windowHeight * 0.65) {
      if(card.classList.contains('active') ===false)card.classList.add('active');
    } else {
      card.classList.remove('active');
    }
  });
});





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

