import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import MobileHome from "./Components/MobileHome";
import Contact from "./Components/Contact";
import MobileAbout from "./Components/MobileAbout";
import CreatorCircle from "./Components/CreatorCircle";
function App() {


  if(window.innerWidth >= 450){
  return (
    <BrowserRouter>
      <Routes>
        <Route path ="/" element = {<Home />} />
        <Route path="creatorcircle" element = {<CreatorCircle/>}/>
        <Route path ="about" element = {<About />} />
        <Route path = "contact" element ={<Contact/>}/>
      </Routes>
    </BrowserRouter>
  );
  }
  else{
    return (
      <BrowserRouter>
        <Routes>
          <Route path ="/" element = {<MobileHome />} />
          <Route path="creatorcircle" element = {<CreatorCircle/>}/>
          <Route path ="about" element = {<MobileAbout />} />
          <Route path = "contact" element ={<Contact/>}/>
        </Routes>
      </BrowserRouter>
    );
  }

}

export default App;
