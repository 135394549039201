import React, { useEffect, useState } from 'react'
import './Page3.css'
import bts_1 from '../../../Assets/bts_1.png'
import bts_2 from '../../../Assets/bts_2.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Page3 = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        })
    }, []);

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % 2);
        }, 5000);
        return () => clearInterval(interval);
    }, []);
    
    return (
        <div className='bts-container card'>
            <div className='bts-carousel'>
                <div className='carousel-dots'>
                    <ul className='heroDots'>
                        <li className={currentSlide === 0 ? "colored-dot" : "dot"}></li>
                        <li className={currentSlide === 1 ? "colored-dot" : "dot"}></li>
                    </ul>
                </div>
                <div className="bts-carousel-inner" style={{ transform: `translateX(-${currentSlide * 50}%)` }}>
                    <img className='bts-img' src={bts_1} alt='behind the scenes' />
                    <img className='bts-img' src={bts_2} alt='behind the scenes' />
                </div>
            </div>
        </div>
    )
}

export default Page3