import React, { useEffect, useState } from 'react'
import menu from '../../../Assets/menu.svg'
import './Navbar.css'
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../Assets/logo.png'
import close from '../../../Assets/cross.png'
const Navbar = () => {

  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu === true) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const location = useLocation();

  const [isSmaller, setIsSmaller] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [prevPosition, setPrevPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;

      if (currentPosition > 2) {
        setIsSmaller(true);
      } else {
        setIsSmaller(false);
      }

      if (currentPosition > 220) {
        if (prevPosition < currentPosition) {
          setIsHidden(true);
        } else if (currentPosition < prevPosition) {
          setIsHidden(false);
        }
      }

      setPrevPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevPosition]);


  useEffect(() => {
    // Update active menu item based on current route
    if (location.pathname === '/creatorcircle') setActiveMenuItem('creator');
    else if (location.pathname === '/about') setActiveMenuItem('about');
    else if (location.pathname === '/contact') setActiveMenuItem('contact');
    else if(location.pathname === '/') setActiveMenuItem('home');
  }, [location]);

  const changeColor = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <nav className={`navbar ${isSmaller ? 'smaller' : ''} ${isHidden ? 'hide' : ''}`} >

      <Link to={"/"}><div className='logo'><img src={logo} className='logo-img' alt='logo' /></div></Link>

      <div className='nav-items'>
        <Link to={"/creatorcircle"}><p className='nav-item'>Creator Circle</p></Link>
        <Link to={"/about"}><p className='nav-item'>About</p></Link>
        <Link to={"/contact"}><p className='nav-item'>Contact</p></Link>
      </div>
      <div className='mobile-menu-container'>
        <img src={menu} className='mobMenu' alt='menu icon' onClick={toggleMenu} />
        <ul className='navMenu' style={{ display: showMenu ? 'flex' : 'none' }}>
          <img src={close} className='close-menu' style={{display:showMenu?'flex':'none'}} alt='close' onClick={toggleMenu} />
          <Link to={"/"}><li className='listItem' style={{ color: activeMenuItem === 'home' ? "#ff5400" : "white" }}
            onClick={() => changeColor('home')}>Home</li></Link>
          <Link to={"/creatorcircle"}><li className='listItem' style={{ color: activeMenuItem === 'creator' ? "#ff5400" : "white" }}
            onClick={() => changeColor('creator')}>Creator Circle</li></Link>
          <Link to={"/about"}><li className='listItem' style={{ color: activeMenuItem === 'about' ? "#ff5400" : "white" }}
            onClick={() => changeColor('about')}>About</li></Link>
          <Link to={"/contact"}><li className='listItem' style={{ color: activeMenuItem === 'contact' ? "#ff5400" : "white" }}
            onClick={() => changeColor('contact')}>Contact</li></Link>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar