import React from 'react';
import Matter from 'matter-js';
import './Tiles.css'

class Tiles extends React.Component {
  constructor(props) {
    super(props);
    this.sceneRef = React.createRef();
  }
  
  componentDidMount() {
    const { Engine, Render, Runner, Bodies, World, Mouse, MouseConstraint, Body } = Matter;
    const width = Math.min(window.innerWidth - 27, 1440);
    const height = Math.min(window.innerHeight, 260);
    const engine = Engine.create({
      gravity: { y: 0.2 },
      positionIterations: 10,
      velocityIterations: 10
    });
    const render = Render.create({
      element: this.sceneRef.current,
      engine: engine,
      options: {
        width: width,
        height: height,
        wireframes: false,
        background: 'transparent',
        pixelRatio: 2
      }
    });

    const wallOptions = { 
      isStatic: true, 
      render: { fillStyle: 'transparent' }, // Make walls transparent to avoid overlap issues
      chamfer:{radius:50}
    };
    
    // Wall thickness (match to div border)
    const wallThickness = 10;
    
    // Rounded corners
    const corners = [
      Bodies.circle(wallThickness / 2, wallThickness / 2, 50, wallOptions), // Top-left corner
      Bodies.circle(width - wallThickness / 2, wallThickness / 2, 50, wallOptions), // Top-right corner
      Bodies.circle(wallThickness / 2, height - wallThickness / 2, 50, wallOptions), // Bottom-left corner
      Bodies.circle(width - wallThickness / 2, height - wallThickness / 2, 50, wallOptions) // Bottom-right corner
    ];
    
    const walls = [
      Bodies.rectangle(width / 2, -wallThickness / 2, width, wallThickness, wallOptions), // Top wall
      Bodies.rectangle(width / 2, height + wallThickness / 2, width, wallThickness, wallOptions), // Bottom wall
      Bodies.rectangle(-wallThickness / 2, height / 2, wallThickness, height, wallOptions), // Left wall
      Bodies.rectangle(width + wallThickness / 2, height / 2, wallThickness, height, wallOptions) // Right wall
    ];
    
    
     // Text labels for tiles
    const labels = ['Photographer', 'Filmmaker', 'Graphic Designer','Illustrators','Editors','Animators','VFX Artist','Producer','Cinematographer', 'Stylist','Marketer','Director','Artist','Artist', 'Writer', 'Musicians'];

    // Function to get the current viewport width
    const getViewportWidth = () => Math.min(window.innerWidth, 860);

    // Function to scale sizes based on viewport width
    const scaleSize = (size) => {
      const minWidth = 260;
      const maxWidth = 860;
      const scale = (getViewportWidth() - minWidth) / (maxWidth - minWidth);
      return Math.max(size * scale, size * 0.5); // Ensure minimum size is half of original
    }

    const tiles = labels.map((label) => {
      const viewportWidth = getViewportWidth();
      const x = viewportWidth /2 + (Math.random() - 0.5) * viewportWidth * 0.8;
      const y = Math.random() * scaleSize(200);
      const width = scaleSize(label.length * 10 + 30);
      const height = scaleSize(40);
      
      const tile = Bodies.rectangle(x, y, width, height, {
        chamfer: { radius: scaleSize(10) },
        render: {
          sprite: {
            texture: this.createTextTexture(label, width, height),
            xScale: 0.5,
            yScale: 0.5
          }
        },
        frictionAir: 0.05,
        restitution: 0.5
      });
      
      return tile;
    });

    const balls = [];
    const colors = ['#f0ff00', '#1680f9', '#ff00a2'];

    for (let i = 0; i < 6; i++) {
      const viewportWidth = getViewportWidth();
      const x = Math.random() * (viewportWidth * 0.8) + (viewportWidth * 0.1);
      const y = Math.random() * scaleSize(300) + scaleSize(50);
      const radius = scaleSize(30);
      
      const ball = Bodies.circle(x, y, radius, {
        render: { fillStyle: colors[i % colors.length] },
        frictionAir: 0.02,
        restitution: 0.7
      });
      
      balls.push(ball);
    }

    World.add(engine.world, [...walls,...corners, ...tiles, ...balls]);

    let mouse = Matter.Mouse.create(render.canvas);
    let mouseConstraint = Matter.MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false
        }
      }
    });
    
    World.add(engine.world, mouseConstraint);

    [...tiles, ...balls].forEach(object => {
      Body.setVelocity(object, {
        x: (Math.random() - 0.5) * 2,
        y: (Math.random() - 0.5) * 2
      });
    });

    Runner.run(Runner.create(), engine);
    Render.run(render);

    // Add this to keep objects within bounds
    Matter.Events.on(engine, 'afterUpdate', function() {
      [...tiles, ...balls].forEach(object => {
        if (object.position.x < 0) Body.setPosition(object, { x: 0, y: object.position.y });
        if (object.position.x > width) Body.setPosition(object, { x: width, y: object.position.y });
        if (object.position.y < 0) Body.setPosition(object, { x: object.position.x, y: 0 });
        if (object.position.y > height) Body.setPosition(object, { x: object.position.x, y: height });
      });
    });
  }
  createTextTexture(text,width,height){
    const scale = 2;
    const canvas = document.createElement('canvas');
    canvas.width = width * scale;
    canvas.height = height * scale;
    const ctx = canvas.getContext('2d'); //ctx : context

    ctx.fillStyle = '#222';
    const radius = window.innerWidth>700?40:20;
    ctx.beginPath();
    ctx.moveTo(radius, 0);
    ctx.lineTo(canvas.width - radius, 0);
    ctx.quadraticCurveTo(canvas.width, 0, canvas.width, radius);
    ctx.lineTo(canvas.width, canvas.height - radius);
    ctx.quadraticCurveTo(canvas.width, canvas.height, canvas.width - radius, canvas.height);
    ctx.lineTo(radius, canvas.height);
    ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - radius);
    ctx.lineTo(0, radius);
    ctx.quadraticCurveTo(0, 0, radius, 0);
    ctx.closePath();
    ctx.fill();


    const vw_width = window.innerWidth;
    ctx.fillStyle = 'white';
    ctx.font = `${32}px Arial`;
    if(vw_width< 650){
      ctx.font = `${20}px Arial`;
    }
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    return canvas.toDataURL();

  }

  render(){
    return <div className='tiles-container' ref={this.sceneRef} />;
  }

}


export default Tiles;