import Bakery from "./Home/Bakery/Bakery"
import Brands from './Home/Brands/Brands'
import Connect from './Home/Connect/Connect'
import Footer from './Home/Footer/Footer';
import Founders from './Home/Founders/Founders'
import Gif from "./Home/Gif/Gif";
import Intro from './Home/Intro/Intro'
import Navbar from './Home/Navbar/Navbar'
import Services from "./Home/Services Slider/Services";
import './Home.css'


function MobileHome() {




  // const containerRef = useRef(null);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   let scrolling = false;
  //   let startY;

  //   const handleTouchStart = (e) => {
  //     startY = e.touches[0].pageY;
  //   };

  //   const handleTouchMove = (e) => {
  //     if (scrolling) return;

  //     const currentY = e.touches[0].pageY;
  //     const diff = startY - currentY;
  //     const threshold = window.innerHeight * 0.1; // 10% of viewport height

  //     if (Math.abs(diff) > threshold) {
  //       scrolling = true;
  //       const direction = diff > 0 ? 1 : -1;
  //       const targetScroll = Math.round(container.scrollTop / window.innerHeight) * window.innerHeight + direction * window.innerHeight;
        
  //       container.scrollTo({
  //         top: targetScroll,
  //         behavior: 'smooth'
  //       });

  //       setTimeout(() => {
  //         scrolling = false;
  //       }, 500);
  //     }
  //   };

  //   container.addEventListener('touchstart', handleTouchStart);
  //   container.addEventListener('touchmove', handleTouchMove);

  //   return () => {
  //     container.removeEventListener('touchstart', handleTouchStart);
  //     container.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);


    return (
    <>
      <div className="home" > 
      {/* ref={containerRef} */}
      <section className="reel"><Navbar /></section>
      <section className="reel"><Intro /></section>
      <section className="reel"><Bakery /></section>
      <section className="services-container"><Services /></section>
      <section className="reel"><Gif /></section>
      <section className="reel"><Founders /></section>
      <section className="reel"><Brands /></section>
      <section className="reel"><Connect /></section>
      <section className="reel"><Footer /></section>
      </div>
      <div style={{display:'none'}}>
        <h1>Digital content production agency in Delhi and Mumbai, India</h1>
      </div>
    </>
    );
  
}


export default MobileHome;
